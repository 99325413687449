import styled from "styled-components"
import { device } from "../../utils/responsive"

export const TeacherListContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(1fr, 3fr);
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  @media ${device.tablet} {
    grid-column-gap: 0;
    grid-row-gap: 1rem;
  }
  @media ${device.laptop} {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }
  @media ${device.laptopL} {
    grid-column-gap: 5rem;
    grid-template-columns: repeat(3, minmax(310px, 1fr));
  }
`
export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
