import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { device } from "../../utils/responsive"

const TeacherDetails = theme => css`
  z-index: 99;
  & > svg {
    transform: scale(2);
    /*  & > linearGradient {
      display: none;
    } */
  }
  & > div {
    /*Teacher Info*/
    & > div:nth-child(1) {
      /*Teacher Pictue*/
      transform: scale(0.5);
      border: 3px solid white;
      bottom: 82%;
    }
    & > div:nth-child(2) {
      /* TeacherBio*/
      transform: scale(1.6);
      bottom: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: center;
      & > span {
        /* TeacherName*/
        font-size: ${theme.fontSize.l};
        color: ${theme.colours.white};
        font-size: 0.7rem;
        font-weight: 800;
        margin: 0.3rem;
        white-space: nowrap;
      }
      & > p {
        /* Paragraph*/
        padding: 0 4rem;
        line-height: 1.3;
        font-style: italic;
      }
    }
    & span:last-child {
      display: none;
    }
  }

  @media ${device.tablet} {
    & > svg {
      transform: scale(2.2);
    }
    & > div {
      /*Teacher Info*/
      & > div:nth-child(1) {
        /*Teacher Pictue*/
        transform: scale(0.6);
        bottom: 90%;
      }

      & > div:nth-child(2) {
        /* TeacherBio*/
        transform: scale(1.3);
        bottom: 95%;

        & > span {
          /* TeacherName*/
          font-size: ${theme.fontSize.l};
          font-size: 0.7rem;
        }
        & > p {
          /* Paragraph*/
          padding: 0;
          line-height: 1.3;
        }
      }
    }
  }

  @media ${device.laptop} {
    & > svg {
      transform: scale(2.7);
    }
    & > div {
      /*Teacher Info*/
      & > div:nth-child(1) {
        /*Teacher Pictue*/
        bottom: 100%;
        transform: scale(0.8);
      }

      & > div:nth-child(2) {
        /* TeacherBio*/
        padding: 0;
        bottom: 90%;
        & > span {
          /* TeacherName*/
          font-size: ${theme.fontSize.sm};
          font-size: 0.7rem;
        }
        & > p {
          /* Paragraph*/
          padding: 0 0.7rem;
          line-height: 1.3;
        }
      }
    }
  }
`
export const TeacherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: 0.7s;
  width: 300px;
  height: 300px;
  text-align: center;
  cursor: pointer;

  ${({ theme, showDetails }) => showDetails && TeacherDetails(theme)}
`
export const TeacherInfo = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`
export const TeacherImg = styled(Img)`
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 50%;
  transition: 0.9s;
`
export const TeacherBio = styled.div`
  display: none;
  position: relative;
  transition: 0.5s;
  z-index: 1000;
  font-family: ${({ theme }) => theme.font.Lato};
  line-height: ${({ theme }) => theme.lineHeight.snug};
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: ${({ theme }) => theme.spacing["5"]};
`
export const TeacherName = styled.span`
  color: ${({ theme }) => theme.colours.black};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-family: ${({ theme }) => theme.font.Montserrat};
  font-size: ${({ theme }) => theme.fontSize["xl"]};
  text-transform: uppercase;
  margin: ${({ theme }) => theme.spacing["4"]} 0;
  letter-spacing: ${({ theme }) => theme.letterSpacing["3"]};
  transition: 0.7s;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fontSize["base"]};
    margin: ${({ theme }) => theme.spacing["2"]} 0;
  }
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSize["sm"]};
    margin: ${({ theme }) => theme.spacing["6"]} 0;
  }
`
/* export const StyledMorph = styled(SVGMorph)`
  position: absolute;
  bottom: 25%;
  width: 100%;
  z-index: 1;
  transform: rotate(${({ index }) => (index * 30) % 360}deg);
  transition: 0.7s;
` */
export const SocialMediaLinks = styled.a`
  margin: 0 ${({ theme }) => theme.spacing["2"]};
  text-decoration: none;
  > svg {
    fill: white;
    width: 20px;
    margin: ${({ theme }) => theme.spacing["2"]} 0;
  }
`
export const TeacherStory = styled(MDXRenderer)`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  line-height: ${({ theme }) => theme.lineHeight.relaxed};
`
export const SocialLinks = styled.div`
  display: inline-block;
`
