import React, { useState, useRef } from "react"
import { useClickAway } from "use-click-away"
import InstagramIcon from "../../images/instagram.svg"
import FacebookIcon from "../../images/facebook.svg"
import Website from "../../images/website.svg"
import SVGMorph from "../SVGMorph/SVGMorph.component"
import {
  SocialMediaLinks,
  TeacherContainer,
  TeacherImg,
  TeacherStory,
  TeacherName,
  TeacherInfo,
  TeacherBio,
  SocialLinks,
} from "./teacher.styles"
import { Element } from "react-scroll"
const Teacher = ({ data, index }) => {
  const {
    body,
    frontmatter: { name, social, featuredimage },
  } = data
  const [showDetails, setShowDetails] = useState(false)
  const clickRef = useRef("")
  useClickAway(clickRef, () => {
    setShowDetails(false)
  })

  return name && featuredimage.src ? (
    <TeacherContainer
      ref={clickRef}
      onClick={() => {
        body ? setShowDetails(!showDetails) : showDetails(false)
      }}
      showDetails={showDetails}
    >
      <Element name={index.toString()}></Element>

      <SVGMorph index={index}></SVGMorph>
      {body && (
        <TeacherInfo>
          {featuredimage.src && (
            <TeacherImg
              imgStyle={{
                objectFit: "cover",
                objectPosition: "50% 20%",
              }}
              fluid={featuredimage.src.childImageSharp.fluid}
              alt={featuredimage.alt}
            ></TeacherImg>
          )}
          <TeacherBio>
            <TeacherName>{name} </TeacherName>
            <TeacherStory>{body}</TeacherStory>
            {social && (
              <SocialLinks>
                {social.instagram && (
                  <SocialMediaLinks href={social.instagram} target="_blank">
                    <InstagramIcon></InstagramIcon>
                  </SocialMediaLinks>
                )}
                {social.website && (
                  <SocialMediaLinks href={social.website} target="_blank">
                    <Website></Website>
                  </SocialMediaLinks>
                )}
                {social.facebook && (
                  <SocialMediaLinks href={social.facebook} target="_blank">
                   <FacebookIcon></FacebookIcon> 
                  </SocialMediaLinks> 
                )}
              </SocialLinks>
            )}
          </TeacherBio>
          <TeacherName>{name} </TeacherName>
        </TeacherInfo>
      )}
    </TeacherContainer>
  ) : null
}

export default React.memo(Teacher)
