import styled from "styled-components"

export const StyledMorph = styled.svg`
  position: absolute;
  bottom: 25%;
  width: 100%;
  z-index: 1;
  transform: rotate(${({ index }) => (index * 30) % 360}deg);
  transition: 0.7s;
`
