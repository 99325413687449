import React from "react"
import styled from "styled-components"
import { H3 } from "../components/page-elements"
import TeacherList from "../components/teacher-list/teacher-list.component"
import SEO from "../components/seo"
import { device } from "../utils/responsive"

const Title = styled(H3)`
  text-transform: uppercase;
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing["3"]};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  line-height: ${({ theme }) => theme.lineHeight.loose};
  padding: 0 1.0875rem 1.45rem;

  @media ${device.laptop} {
    padding: 0;
  }
`
const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing["20"]};
  display: flex;
  flex-direction: column;
`

const Subtitle = styled.span`
  text-align: center;
  letter-spacing: 0.3rem;
  /* font-weight: 600; */
  padding: 3rem;
`
const Teachers = () => (
  <div>
    <SEO title="Green Yoga Teachers and Instructors" />
    <TitleContainer>
      <Title>
        Our teachers are here to make your yogic experience authentic as can be.
      </Title>
      <Subtitle>
        We are a collective of more than <strong>50 yoga teachers</strong> from
        all backgrounds and spiritual journeys sharing the belief in the
        transformational power of yoga.{" "}
      </Subtitle>
    </TitleContainer>
    <TeacherList></TeacherList>
  </div>
)

export default Teachers
