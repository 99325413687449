import React, { useState } from "react"
import Teacher from "../teacher/teacher.component"
import { TeacherListContainer, LoadMoreContainer } from "./teacher-list.styles"
import { useStaticQuery, graphql } from "gatsby"
import CustomButton from "../custom-button/custom-button.component"
import { scroller } from "react-scroll"
const TeacherList = () => {
  const [offset, setOffset] = useState(9)
  const {
    allMdx: { nodes },
  } = useStaticQuery(graphql`
    query TeachersQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/(teachers/)/" } }
        sort: { fields: frontmatter___id }
      ) {
        nodes {
          id
          body
          frontmatter {
            id
            name
            social {
              instagram
              website
              facebook
            }
            featuredimage {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  nodes.sort((a, b) => a.frontmatter.id - b.frontmatter.id)
  return (
    <div>
      <TeacherListContainer>
        {nodes &&
          nodes.map((node, index) => {
            return index < offset ? (
              <Teacher key={node.id} data={node} index={index}></Teacher>
            ) : null
          })}
      </TeacherListContainer>
      <LoadMoreContainer>
        {offset < nodes.length && (
          <CustomButton
            onClick={() => {
              scroller.scrollTo(`${(offset - 6).toString()}`, {
                duration: 10,
                delay: 0,
                smooth: true,
                offset: 600,
              })
              setOffset(offset + 6)
            }}
          >
            Load More
          </CustomButton>
        )}
      </LoadMoreContainer>
    </div>
  )
}

export default React.memo(TeacherList)
